$light: #fff;
$dark: #1A1A1A;

//Background colours
$background-colour-default-light: #F7F7F7;

$primary-color: #26BD1E;
// $primary-color: #13b1cd;
$secondary-color: #f5f8f9;
$event-color: #c54041;
$law-color: #b99769;
$black: #242424;
$border-color:#dedede;
$primary-font:'Montserrat', sans-serif;
$secondary-font:'Poppins', sans-serif;
$extra-font:'Hind', serif;