#navbar{
  background: rgba(34,35,40,1);
  box-shadow: 0 1px 2px rgba(0,0,0,.05);
  li{
    padding-left: 15px;
    @include desktop {
      padding-left: 0;
    }
  }

 .nav-link{
    font-family: $secondary-font;
    font-weight: 500;
    color: $light;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .5px;
    transition: all .25s ease;
  }
    
  .nav-link:hover, .nav-link:focus,
  .active .nav-link {
    color: $primary-color;
  }


  .btn{
    padding: .7rem 1.5rem .5rem;
    color: $light;
    @include desktop {
      margin: 15px 0 10px;
    }
  }

}

.header-top{
  background: rgba(34,35,40,1);
  color: #919194;
  border-bottom: 1px solid rgba(255,255,255,.05);

  .header-top-socials {
    border-right: 1px solid rgba(255,255,255,.05);
    padding: 12px 0px;
  }
  
  .header-top-socials {
    margin-left: -8px;
    a {
      color: #919194;
      margin-right: 8px;
      font-size: 16px;
      padding: 0 8px;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .header-top-info{
    color: #919194;
    font-size: 16px;

    a span{
      color: $light;
    }
    a{
      margin-left: 35px;
      color: #919194;
    }
  }
}

.navbar-toggler {
  padding: 0;
  font-size: 1.5rem;
  color: #fff;
  &:focus {
    outline: 0;
  }
}


.navbar-brand{
  color: $light;
  font-weight: 600;
  letter-spacing: 1px;

  span{
    color: $primary-color;
  }
}

.dropdown-menu{
  padding: 0px;
  border: 0;
  border-radius: 0px;
  @include desktop {
    text-align: center;
    float: left !important;
    width: 100%;
    margin: 0;
  }

  li:first-child {margin-top: 5px}
  li:last-child {margin-bottom: 5px}
}

.dropdown-toggle::after {
    display: none;
}

.dropleft .dropdown-menu,
.dropright .dropdown-menu{
  margin: 0;
}
.dropleft .dropdown-toggle::before,
.dropright .dropdown-toggle::after {
  font-weight: bold;
  font-family: 'Font Awesome 5 Free';
  border: 0;
  font-size: 10px;
  vertical-align: 1px;
}
.dropleft .dropdown-toggle::before {
  content: "\f053";
  margin-right: 5px;
}
.dropright .dropdown-toggle::after {
  content: "\f054";
  margin-left: 5px;
}

.dropdown-item{
  padding: .8rem 1.5rem .55rem;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  @include desktop {
    padding: .6rem 1.5rem .35rem;
  }
}

.dropdown-submenu.active > .dropdown-toggle,
.dropdown-submenu:hover > .dropdown-item,
.dropdown-item.active,
.dropdown-item:hover{
  background: $primary-color;
  color: $light;
}

ul.dropdown-menu li {
    padding-left: 0px!important;
}

@media (min-width:992px) {
  .dropdown-menu{
    transition:all .2s ease-in, visibility 0s linear .2s, transform .2s linear;
    display: block;
    visibility: hidden;
    opacity: 0;
    min-width: 200px;
    margin-top: 15px;

    li:first-child {margin-top: 10px}
    li:last-child {margin-bottom: 10px}
  }
  .dropleft .dropdown-menu,
  .dropright .dropdown-menu{
    margin-top: -10px;
  }
  .dropdown:hover > .dropdown-menu{
    visibility: visible;
    transition: all .45s ease 0s;
    opacity: 1;
  }
}

